<template>
	<div>
		<div class="container darkBack">
			<div class="font18 whiteFont title">
				<span>体育彩票销售额（万元）</span>
				<span class="deadline">（截止{{chartDate2}}）</span>
				<!-- <div style="display:flex;">
					<div :class="['btn1' , selected1 ? 'selected' : '']" @click="changeData('今年')">今年</div>
					<div :class="['btn2' , selected2 ? 'selected' : '']" @click="changeData('全部')">全部</div>
				</div> -->
			</div>
			<div id="lottery"></div>
		</div>
	</div>
</template>

<script>
import bus from "../../../assets/js/bus"
    import {getAjax, timestamp} from "../../../assets/js/websocket";

	export default {
		data() {
			return{
				lottery:{
					yAxis:['新昌县', '诸暨市', '柯桥区', '越城区', '上虞区','嵊州市'],
					data:[]
				},
				selected1:true,
				selected2:false,
				chartDate2: '',
			}
		},
		methods:{
			// 绘制彩票销售额表
			drawLottery(number){
				let that = this;
				// let myChart = that.$echarts.init(document.getElementById("lottery"));
				
				let myChart = this.$echarts.getInstanceByDom(document.getElementById("lottery"))
				if (myChart == null) { // 如果不存在，就进行初始化
					myChart = this.$echarts.init(document.getElementById("lottery"));
				}

				let data = [];
				number.data.forEach((item,index)=>{
					data.push(item)
				})
				// console.log(data)
				let option = {
					xAxis: {
						// 显示网格线
						splitLine:{
							show: true,
							lineStyle:{
								color:'#2D94D033',
							}
						},
						// 不显示刻度值
						axisLabel:{show:false}
					},
					grid: {         // 间距
						left: '6%',  
						right: '12%', 
						top:'20%', 
						bottom: '6%',  
						containLabel: true,
					},
					yAxis: {
						type: 'category',
						data: number.yAxis,
						inverse: true,
						// animationDuration: 300,
						// animationDurationUpdate: 300,
						//y轴显示及颜色
						axisLine:{
							show:true,
							lineStyle:{
								color:'#2D94D0',
							}
						},
						// 刻度不显示
						axisTick:{show:false},
						// y轴字体样式
						axisLabel:{
							color:'#7EA7E2',
							fontSize:'0.14rem'
						},
					},
					series: [{
						// 实时排序
						// realtimeSort: true,
						type: 'bar',
						data: number.data,
						// 柱子宽度
						barWidth:'40%',
						// 柱子间距
						barCategoryGap:10,
						// 数据显示
						// label: {
						// 	show: true,
						// 	position: 'right',
						// 	color:"#F1F1F1",
						// 	valueAnimation: true,
                        //     fontSize:'0.14rem',
						// },
						itemStyle: {
							normal: {
								color:new that.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
									offset: 0,
									color: "#2D94D0" // 0% 处的颜色
								}, {
									offset: 1,
									color: "#3AE6CE" // 100% 处的颜色
								}], false),
								label: {
									show: true,
									position: ["50%","-90%"],
									color:"#F1F1F1",
									valueAnimation: true,
									fontSize:'0.14rem',
								},
							}
						},
					}],
					// animationDuration: 1000,
					// animationDurationUpdate: 3000,
					// animationEasing: 'linear',
					// animationEasingUpdate: 'linear'
				};
				
				// 使用刚指定的配置项和数据显示图表。
				myChart.setOption(option);
				// 图表自动缩放
				window.addEventListener("resize",function(){
				  	myChart.resize();
				});
			},
			// 切换数据、
			changeData(type){
				let that = this;
				// console.log(type)
				let date;
				if(type == "今年"){
					that.selected1 = true;
					that.selected2 = false;
					date = 1;
				}else{
					that.selected1 = false;
					that.selected2 = true;
					date = 0;
				}
				var data = {
					token: window.getToken(),
					app_id: window.appId,
					time: timestamp(),
				}
				getAjax({
					url: "/map/screen/getSportsBusiness?date="+date,
					method:"GET",
					data:data,
					success:function(res){
						// console.log(res)
						if(res.result){
							that.lottery.yAxis = [];
							that.lottery.data = [];
							res.data.area_data.forEach(item=>{
								that.lottery.yAxis.push(item.name);
								that.lottery.data.push(item.num);
							})
							that.drawLottery(that.lottery);
						}
					},
				})
			},
			// 获取彩票销售额
        getLotterySale(){
            let that = this;
            let url = window.appHost+`api/v1/screen/Lottery/sale`
            let result = window.ajaxRequest(url)
            // console.log(result);
            that.lottery.yAxis = [];
			that.lottery.data = [];
            for(let i in result.data.data){
                that.lottery.yAxis.push(i);
                that.lottery.data.push(result.data.data[i]);
            }
			console.log(that.lottery)
            that.chartDate2 = result.data.deadline;
            that.drawLottery(that.lottery);
        },

		},
		mounted() {
			this.getLotterySale();
			let that = this;
			// that.drawLottery(that.lottery);
			// bus.$on('lottery_sales',function(res){
			// 	// console.log(res)
			// 	that.lottery.yAxis = [];
			// 	that.lottery.data = [];
			// 	res.forEach((item,index)=>{
			// 		that.lottery.yAxis.push(item.name);
			// 		that.lottery.data.push(item.num);
			// 	})
			// 	that.drawLottery(that.lottery);
			// })
			// let url = window.appHost+"/api/v1/screen/Lottery/vital"
			// let result = window.ajaxRequest(url)
			// // console.log(result)
			// that.lottery = {
			// 	yAxis:[],
			// 	data:[]
			// }
			// for(let i in result.data){
			// 	that.lottery.yAxis.push(i);
			// 	that.lottery.data.push(result.data[i]);
			// }
			// that.drawLottery(that.lottery);

		},
		destroyed () {
			// bus.$off('lotterySales');
		}
	}
</script>

<style scoped>
	.container{
		width: 5.06rem;
		height: 2.93rem;
		padding: 0.2rem;
		position: relative;
	}
	#lottery{
		width: 5.00rem;
		height: 2.80rem;
		margin-left:-0.2rem;
		/* transform: scaleX(0.9); */
		margin-top: -0.4rem;
		/* border:1px solid red; */
	}

	.deadline {
    	display: block;
    	font-size: 0.06rem !important;
	}

	/* .title{
		display: flex;
		justify-content: space-between;
		align-items: center;
	} */
	.btn1,.btn2{
		border: 0.01rem solid #19d4fb;
		width: 0.6rem;
		height: 0.24rem;
		line-height: 0.24rem;
		text-align: center;
		color: #15dcfe;
		cursor:pointer;
		z-index: 10;
		font-size: 0.16rem;
	}
	.btn1{
		border-top-left-radius: 0.5rem;
		border-bottom-left-radius: 0.5rem;
		
	}
	.btn2{
		border-top-right-radius: 0.5rem;
		border-bottom-right-radius: 0.5rem;
	}
	.selected{
		background: #15dcfe;
		color: white;
	}
</style>


